import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { INFORMATION_EDIT, INFORMATION_SHOW, RESET_PASSWORD } from '../../constants/routes';
import actions from '../../actions/auth-actions';
import Spinner from '../utils/spinner';
import { withTranslation } from 'react-i18next';

class PersonalInfo extends Component {
  params = new URLSearchParams(this.props.location.search);
  flowId = this.params.get('flow');
  constructAddress = (addressObj) => {
    const address = [];
    const keys = ['area', 'neighbourhood'];
    keys.forEach((key) => {
      if (addressObj && addressObj[key]) {
        address.push(addressObj[key]);
      }
    });
    return address.join(', ');
  };
  async init() {
    try {
      const { dispatch } = this.props;
      if (this.flowId) {
        dispatch(
          actions.getSettingsFlow(this.flowId, undefined)
        )
      }

    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount = async () => {
    await this.init();
  };

  render() {
    const { loading, user, flow, t } = this.props;

    let {
      email,
      phone,
      telegram,
      language,
    } = user?.identity?.traits || {
      picture: null,
    };


  

    const deleteAccountFields = {
      title: 'DeleteAccount',
      description: '',
      value: '',
    };

    const emailField = {
      title: 'Email',
      description: 'lorem ipsum',
      value: email || [],
      text: t('information_show.email.title'),

    };

    const phoneField = {
      title: 'Phone',
      description: 'lorem ipsum',
      value: phone,
      text: t('information_show.phone.title'),

    };

    const telegramField = {
      title: 'Telegram',
      description: 'lorem ipsum',
      value: telegram,
      text: t('information_show.telegram.title'),

    };

    const languageField = {
      title: 'Language',
      description: 'lorem ipsum',
      value: language,
      text: t('information_show.language.title'),

    };

    const webAuthNField = {
      title: 'Passwordless',
      description: 'lorem ipsum',
      value: {},
      text: t('information_show.webauthn.title'),

    };
    if (flow?.ui?.messages && flow?.ui?.messages[0] && flow?.ui?.messages[0]?.id === 1060001) {
      return (<Redirect to={RESET_PASSWORD} />)
    }
    if (loading) {
      return <Spinner loading={loading} />;
    } else {
      return (
        <div className="p-5 md:p-8 lg:p-10 w-full xl:w-5/6">
          <div className="text-center">
            <h1 className="text-3xl font-semibold text-center text-black mb-2">
              {t('personal_info.title')}
            </h1>
            <span className="text-center mb-8 text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.
            </span>
          </div>

          {/* <div
            className="w-full xl:w-5/6 lg:mx-auto p-6 border border-gray-200 rounded mt-8"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div>
              <span className="text-2xl font-bold block mb-2">{t('personal_info.basic_info.title')}</span>
              <span className="text-gray-500">
                {t('personal_info.basic_info.description')}
              </span>
            </div>
            <div className="mt-5">
              <div className="w-full mb-4">
                <div className="md:flex items-center">
                  <div className="w-3/12">
                    <p className="block mb-4 font-semibold">{t('personal_info.basic_info.fields.photo')}</p>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center mb-4">
                      <div className="w-14 h-14 object-cover rounded-full text -center">
                        <img
                          className="w-full h-full rounded-full"
                          src={picture}
                          alt=""
                        />
                      </div>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: ImageFields },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8 cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div className="w-full mb-4">
                <div className="md:flex items-center">
                  <div className="w-3/12">
                    <p className="block mb-4 font-semibold">{t('personal_info.basic_info.fields.display_name')}</p>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center mb-4">
                      <div className="block">
                        <p className="block">{display_name}</p>
                      </div>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: nameFields },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div className="w-full">
                <div className="md:flex items-center">
                  <div className="w-3/12 mb-4 md:mb-0">
                    <p className="block font-semibold">{t('personal_info.basic_info.fields.address')}</p>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center">
                      <p className="block pr-4">
                        {addressString === '' ? '-' : addressString}
                      </p>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: addressFields },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8 cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div
            className="w-full xl:w-5/6 lg:mx-auto p-6 border border-gray-200 rounded mt-8"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div>
              <span className="text-2xl font-bold block mb-2">
                {t('personal_info.contact_info.title')}
              </span>
              <span className="text-gray-500">
                {t('personal_info.contact_info.description')}
              </span>
            </div>
            <div className="mt-5">
              <div className="w-full mb-4">
                <div className="md:flex items-center">
                  <div className="w-3/12">
                    <p className="block mb-4 font-semibold">
                      {t('personal_info.contact_info.fields.email')}

                    </p>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center mb-4">
                      <p className="block">{email && email[0]}</p>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: emailField },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div className="w-full mb-4">
                <div className="md:flex items-center">
                  <div className="w-3/12">
                    <p className="block mb-4 font-semibold">
                      {t('personal_info.contact_info.fields.phone')}

                    </p>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center mb-4">
                      <div className="block">
                        <p className="block">
                          {phone?.number
                            ? `+${phone?.dial_code} ${phone?.number}`
                            : '-'}
                        </p>
                      </div>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: phoneField },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div className="w-full">
                <div className="md:flex items-center">
                  <div className="w-3/12 mb-4 md:mb-0">
                    <p className="block font-semibold">
                      {t('personal_info.contact_info.fields.telegram')}

                    </p>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center">
                      <p className="block">
                        {telegram?.number
                          ? `+${telegram?.dial_code} ${telegram?.number}`
                          : '-'}
                      </p>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: telegramField },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-full xl:w-5/6 lg:mx-auto p-6 border border-gray-200 rounded mt-8"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div>
              <span className="text-2xl font-bold block mb-2">
                {t('personal_info.preferences.title')}

              </span>
              <span className="text-gray-500">
                {t('personal_info.preferences.description')}
              </span>
            </div>
            <div className="mt-5">
              <div className="w-full">
                <div className="md:flex items-center">
                  <div className="w-3/12 mb-4 md:mb-0">
                    <p className="block font-semibold">
                      {t('personal_info.preferences.fields.language')}

                    </p>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center">
                      <p className="block">{language?.value}</p>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: languageField },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-full xl:w-5/6 lg:mx-auto p-6 border border-gray-200 rounded mt-8"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div>
              <span className="text-2xl font-bold block mb-2">
                {t('personal_info.webauthn.title')}
              </span>
              <span className="text-gray-500">
                {t('personal_info.webauthn.description')}
              </span>
            </div>
            <div className="mt-5">
              <div className="w-full">
                <div className="md:flex items-center">
                  <div className="w-3/12 mb-4 md:mb-0">
                    <div className="block font-semibold">
                      <div className="flex-col gap-2">
                        <i className="fas fa-fingerprint text-3xl"></i>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center">
                      <p className="block">

                        {t('personal_info.webauthn.fields.register')}

                      </p>
                      <Link
                        to={{
                          pathname: INFORMATION_EDIT,
                          state: { fields: webAuthNField },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className='mt-5' />
            <div className="mt-5">
              <div className="w-full">
                <div className="md:flex items-center">
                  <div className="w-3/12 mb-4 md:mb-0">
                    <div className="block font-semibold">
                      <div className="flex-col gap-2">
                        {t('personal_info.webauthn.fields.password.title')}
                      </div>
                    </div>
                  </div>
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center">
                      <p className="block">
                        {t('personal_info.webauthn.fields.password.description')}

                      </p>
                      <Link
                        to={{
                          pathname: RESET_PASSWORD,
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-full xl:w-5/6 lg:mx-auto p-6 border border-gray-200 rounded mt-8"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div>
              <span className="text-2xl font-bold block mb-2">
                {t('personal_info.delete_account.title')}

              </span>
              <span className="text-gray-500">
                {t('personal_info.delete_account.description')}
              </span>
            </div>
            <div className="mt-5">
              <div className="w-full">
                <div className="md:flex items-center">
                   <div className="w-3/12 mb-4 md:mb-0">
                    {/* <div className="w-14 h-14 object-cover text -center">
                       <img
                        className="w-full h-full rounded-full"
                        src={picture}
                        alt=""
                      /> 
                    </div> */}
                    <p className="block">{email.length && email[0]}</p>
                  </div> 
                  <div className="md:w-9/12">
                    <div className="flex justify-between items-center">
                      <p className="block"></p>
                      <Link
                        to={{
                          pathname: INFORMATION_SHOW,
                          state: { fields: deleteAccountFields },
                        }}
                      >
                        <i className="text-3xl fas fa-chevron-right mr-8  cursor-pointer"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: state.auth.user,
  flow: state.auth.settingsFlow,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(PersonalInfo)));

