const { Component } = require('react');

class FieldWrapper extends Component {
  render() {
    const { error, required, title, element, classes = '' } = this.props;
    return (
      <div className={`flex flex-wrap -mx-3 mb-4 ${classes}`}>
        <div className="w-full px-1">
          <label
            className="flex tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor={element}
          >
            {title}
            {required && <span className="text-red ml-2">*</span>}{' '}
          </label>
          {this.props.children}
          {error && (
            <div className="flex">
              <div className="group relative text-sm rounded-md p-0.5 flex mr-1">
                <i className="fas fa-exclamation-circle text-red"></i>
                <span className="absolute hidden group-hover:flex -left-5 -top-2 -translate-y-full w-64 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700">
                  {error}
                </span>
              </div>
              <span className="text-red text-sm">
                {error?.length > 50 ? `Invalid ${title}` : error}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default FieldWrapper;
