import { toast } from 'react-toastify';
import bbaClient, { bbaAuthClient } from './config';
import routes from './routes';
import { MASTER_API, NETWORK_API } from '../../constants/urls';
import { ClientNotConfiguredError } from 'components/ory/errors';
import getStore from 'store';
import { MasterConnectionFailedError } from 'components/ory/errors';
import { MasterAuthenticationFailedError } from 'components/ory/errors';
export const registerBusiness = async (identity, baseUrl) => {
  try {
    const values = identity?.traits;
    let formData = new FormData();
    formData.append('business_user', values.is_business);
    formData.append('ory_identity_id', identity?.id);
    formData.append('email', values?.email[0]);
    formData.append('role', values?.role || 'subscriber');

    const response = await bbaClient(baseUrl).post(
      routes.CREATE_WP_USER,
      formData
    );
    return response;
  } catch (error) {
    toast.error('Something went wrong when creating Business Account');
    throw error;
  }
};

export const getConnectedUsers = async (ory_identity_id, baseUrl) => {
  try {
    const { data } = await bbaClient(baseUrl).get(
      `${routes.GET_CONNECTED_USERS}?ory_identity_id=${ory_identity_id}`
    );
    if (data?.message === 'No data found!') {
      toast.warning('No Users found for ' + baseUrl);
      return [];
    }
    return data;
  } catch (error) {
    toast.error('Failed to retrieve BBA Accounts');
    throw error;
  }
};

const convertBase64ToFile = async (base64String, fileName, fileExtension) => {
  const res = await fetch(base64String);
  const blob = await res.blob();
  return new File([blob], `${fileName}.${fileExtension}`, {
    type: `image/${fileExtension}`,
  });
};

const getUploadedImagePath = (baseUrl, fileName, extension) => {
  const date = new Date();
  const year = date.getUTCFullYear();
  const formatter = new Intl.DateTimeFormat('en-GB', {
    month: '2-digit',
  });
  const month = formatter.format(date);
  return `${baseUrl}/wp-content/uploads/${year}/${month}/${fileName}.${extension}`;
};

export const uploadProfileImage = async (ory_identity_id, image, baseUrl) => {
  try {
    const [user] = await getConnectedUsers(ory_identity_id, baseUrl);
    if (user) {
      const { accounts } = user;
      const account = accounts.find((ac) => ac.default_account) || accounts[0];
      if (image) {
        const imageExtension = image.split(';')[0].split('/')[1];
        const fileName = `${ory_identity_id}_${user?.ID}_${account?.id
          }_${new Date().getTime()}`;
        const file = await convertBase64ToFile(image, fileName, imageExtension);
        const formData = new FormData();
        formData.append('post_type', 'bba_account');
        formData.append('simple_image', file);
        const client = await bbaAuthClient(baseUrl);
        const { data } = await client.post(
          `${routes.POST}/${account?.id}`,
          formData
        );
        const imagePath = getUploadedImagePath(
          baseUrl,
          fileName,
          imageExtension
        );
        return { message: data?.message, imagePath };
      }
      const formData = new FormData();
      formData.append('post_type', 'bba_account');
      formData.append('simple_image', '');
      const client = await bbaAuthClient(baseUrl);
      const { data } = await client.post(
        `${routes.POST}/${account?.id}`,
        formData
      );
      return { message: data?.message, imagePath: '' };
    }
  } catch (error) {
    toast.error('Failed to upload profile picture');
    throw error;
  }
};

export const setDefaultAccount = async (userId, bbaId, baseUrl) => {
  try {
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('bba_uuid', bbaId);
    const client = await bbaAuthClient(baseUrl);
    const { data } = await client.post(routes.SET_DEFAULT_ACCOUNT, formData);
    return data;
  } catch (error) {
    toast.error('Failed to set default account');
    throw error;
  }
};

export const getDefaultAccount = async (userId, baseUrl) => {
  try {
    const apiUrl = `${routes.GET_DEFAULT_ACCOUNT}?user_id=${userId}`;
    const { data } = await bbaClient(baseUrl).get(apiUrl);
    return data;
  } catch (error) {
    toast.error('Failed to retrieve default account');
    throw error;
  }
};

export const getNetworkConfig = async () => {
  try {
    const { data } = await bbaClient(NETWORK_API).get(routes.GET_NETWORKS);
    const filteredData = data?.masters?.find(master => MASTER_API === master?.master_site_url);

    if (filteredData?.general_client?.id && filteredData?.general_client?.url) {
      return filteredData;
    }

    throw new ClientNotConfiguredError()
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getMasterConfig = async () => {
  try {

    const networkConfig = getStore().getState()?.bba?.networkConfig;
    if (!networkConfig) {
      return;
    }

    if ((networkConfig.admin_username &&
      networkConfig.application_password &&
      networkConfig.master_site_url) === false) {
      throw new MasterConnectionFailedError();
    }

    const { admin_username: username, application_password: password, master_site_url: apiUrl } = networkConfig;
    const token = await getToken(apiUrl, username, password);
    if (!token) {
      throw new MasterAuthenticationFailedError();
    }

    const masterOptions = {
      method: 'GET',
      url: routes.GET_MASTER,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    const response = await bbaClient(apiUrl)(masterOptions);
    if (!response?.data?.client?.general_client?.secret) {
      throw new ClientNotConfiguredError();
    }

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getToken = async (apiUrl, username, password) => {
  try {
    const tokenRequestOptions = {
      method: 'POST',
      url: routes.GENERATE_TOKEN,
      data: {
        username: username,
        password: password
      }
    };
    const { data: tokenResponse } = await bbaClient(apiUrl)(tokenRequestOptions);
    return tokenResponse?.data?.token;
  } catch (error) {
    console.log(error);
  }

};

// export const validateUsername = async (username) => {
//   try {
//     const formData = new FormData();
//     formData.append('username', username);
//     const token = await getToken();
//     console.log(token)
//     const options = {
//       mode: 'no-cors',
//       headers: {
//         'Authorization': 'Bearer ' + token
//       },
//       body: formData,
//       method: 'POST'
//     };
//     const apiUrl = `${env.BBA_API_URL}${routes.VALIDATE_USERNAME}`
//     const myHeaders = new Headers();
//     myHeaders.append('Authorization', 'Bearer ' + token);
//     const responseJson = await fetch('https://dev.hashapp.net/wp-json/bba/check_username_availability', {
//       headers: myHeaders,
//       body: formData,
//       method: 'POST',
//     });
//     console.log(responseJson);
//     const responseData = await responseJson.json();
//     console.log(responseData)
//     return responseData;
//   } catch (error) {
//     console.log('error', error)
//     throw error;
//   }
// }

