import { Route, Switch } from 'react-router-dom';
import accountList from './components/account-list/account-list';
import login from './components/auth/login';
import logout from './components/auth/logout';
import verify from './components/auth/verify';
import adminHome from './components/admin/home/adminHome';
import Home from './components/home/home';
import identitiesEdit from './components/admin/identities/identities-edit';
import identitiesList from './components/admin/identities/identities-list';
import Navbar from './components/navbar/navbar';
import personalInfo from './components/personal-info/personal-info';
import personalInfoVerification from './components/personal-info/personal-info-verification';
import recoveryEmail from './components/security/recovery-email';
import resetPassword from './components/security/reset-password';
import stepVerification from './components/security/step-verification';
import Sidebar from './components/sidebar/sidebar';
import { useTranslation } from 'react-i18next';

import {
  ACCOUNT_LIST,
  HOME,
  LOGIN,
  LOGOUT,
  PERSONAL_INFO,
  PERSONAL_INFO_VERIFICATION,
  RECOVERY_EMAIL,
  RESET_PASSWORD,
  STEP_VERIFICATION,
  VERIFY,
  ADMIN_HOME,
  IDENTITIES_LIST,
  IDENTITIES_EDIT,
  IDENTITIES_INFORMATION,
  INFORMATION_SHOW,
  INFORMATION_EDIT,
  CLIENT_LIST,
  CLIENT_CREATE,
  CLIENT_INFORMATION,
  CLIENT_EDIT,
  TERMS_AND_CONDITIONS,
} from './constants/routes';
import identitiesInformation from './components/admin/identities/identities-information';
import informationShow from 'components/personal-info/information-show';
import informationEdit from 'components/personal-info/information-edit';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import clientList from 'components/admin/clients/client-list';
import clientCreate from 'components/admin/clients/client-create';
import clientInformation from 'components/admin/clients/client-information';
import clientEdit from 'components/admin/clients/client-edit';
import termsAndConditions from 'components/auth/terms-and-conditions';

const App = (props) => {
  const [style, setStyle] = useState('');
  const { i18n } = useTranslation();
  useEffect(() => {
    if (props.sidebar.isOpen) {
      setStyle('md:ml-64');
    } else {
      setStyle('md:ml-0');
    }
  }, [props.sidebar.isOpen]);

  useEffect(() => {
    if (props.user?.identity?.traits?.language?.code) {
      const lang = props.user?.identity?.traits?.language?.code;
      i18n.changeLanguage(lang);
    }
  }, [props.user])
  return (
    <>
      <Sidebar />
      <div className={`md:ml-64 animate-sidebar ${style}`}>
        <Navbar />
        <div className="pt-16">
          <Switch>
            <Route path={HOME} exact component={Home} />
            <Route path={PERSONAL_INFO} exact component={personalInfo} />
            <Route path={INFORMATION_SHOW} exact component={informationShow} />
            <Route path={INFORMATION_EDIT} exact component={informationEdit} />
            <Route
              path={PERSONAL_INFO_VERIFICATION}
              exact
              component={personalInfoVerification}
            />
            <Route
              path={STEP_VERIFICATION}
              exact
              component={stepVerification}
            />
            <Route path={RESET_PASSWORD} exact component={resetPassword} />
            <Route path={RECOVERY_EMAIL} exact component={recoveryEmail} />
            <Route path={ACCOUNT_LIST} exact component={accountList} />
            <Route path={ADMIN_HOME} exact component={adminHome} />
            <Route path={IDENTITIES_LIST} exact component={identitiesList} />
            <Route
              path={`${IDENTITIES_EDIT}/:id`}
              exact
              component={identitiesEdit}
            />
            <Route
              path={`${IDENTITIES_INFORMATION}/:id`}
              exact
              component={identitiesInformation}
            />
            <Route path={CLIENT_LIST} exact component={clientList} />
            <Route path={CLIENT_CREATE} exact component={clientCreate} />
            <Route
              path={`${CLIENT_INFORMATION}/:id`}
              exact
              component={clientInformation}
            />
            <Route path={`${CLIENT_EDIT}/:id`} exact component={clientEdit} />
            <Route path={VERIFY} exact component={verify} />
            <Route path={LOGIN} exact component={login} />
            <Route path={TERMS_AND_CONDITIONS} exact component={termsAndConditions} />
            <Route path={LOGOUT} exact component={logout} />
          </Switch>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  user: state.auth.user,
});
export default connect(mapStateToProps)(App);

