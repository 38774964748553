import actions from '../actions/hydra-actions';

const initialState = {
  loading: false,
  clients: [],
  client: undefined,
  count: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_CLIENT_SITES_STARTED:
      return {
        ...state,
        loading: true,
        clients: [],
        count: 0,
      };
    case actions.GET_CLIENT_SITES_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: payload?.data,
        count: payload?.count,
      };
    case actions.GET_CLIENT_SITES_ERROR:
      return {
        ...state,
        loading: false,
        clients: [],
        count: 0,
      };
    case actions.CREATE_CLIENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_CLIENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        client: undefined,
        loading: false,
        clients:
          state.clients?.filter(
            (client) => client?.client_id !== payload?.clientId
          ) || state.clients,
        count: state.count - 1,
      };
    case actions.GET_CLIENT_SITE_BY_ID_STARTED:
      return {
        ...state,
        client: undefined,
        loading: true,
      };
    case actions.GET_CLIENT_SITE_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_CLIENT_SITE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        client: payload?.data,
      };
    case actions.EDIT_CLIENT_STARTED:
      return {
        ...state,
        loading: true,
        client: undefined,
        clients: [],
      };
    case actions.EDIT_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

