import { IDENTITIES_INFORMATION } from 'constants/routes';
import showToast from '../components/ory/toast';
import { getHistory } from '../store';
import { getIdentityById } from 'api/ory/service';
import { getIdentities } from 'api/ory/service';
import { updateIdentityById } from 'api/ory/service';
import { deleteIdentityById } from 'api/ory/service';

const prefix = 'ADMIN';
const actions = {
  GET_IDENTITY_LIST_STARTED: `${prefix}_GET_IDENTITY_LIST_STARTED`,
  GET_IDENTITY_LIST_SUCCESS: `${prefix}_GET_IDENTITY_LIST_SUCCESS`,
  GET_IDENTITY_LIST_ERROR: `${prefix}_GET_IDENTITY_LIST_ERROR`,
  UPDATE_IDENTITY_STARTED: `${prefix}_UPDATE_IDENTITY_STARTED`,
  UPDATE_IDENTITY_SUCCESS: `${prefix}_UPDATE_IDENTITY_SUCCESS`,
  UPDATE_IDENTITY_ERROR: `${prefix}_UPDATE_IDENTITY_ERROR`,
  GET_IDENTITY_BY_ID_STARTED: `${prefix}_GET_IDENTITY_BY_ID_STARTED`,
  GET_IDENTITY_BY_ID_SUCCESS: `${prefix}_GET_IDENTITY_BY_ID_SUCCESS`,
  GET_IDENTITY_BY_ID_ERROR: `${prefix}_GET_IDENTITY_BY_ID_ERROR`,
  DELETE_IDENTITY_BY_ID_STARTED: `${prefix}_DELETE_IDENTITY_BY_ID_STARTED`,
  DELETE_IDENTITY_BY_ID_SUCCESS: `${prefix}_DELETE_IDENTITY_BY_ID_SUCCESS`,
  DELETE_IDENTITY_BY_ID_ERROR: `${prefix}_DELETE_IDENTITY_BY_ID_ERROR`,

  getIdentitiesList: (pagination = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_IDENTITY_LIST_STARTED,
      });
      const data = await getIdentities(pagination);
      dispatch({
        type: actions.GET_IDENTITY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_IDENTITY_LIST_ERROR,
      });
      console.error(error);
    }
  },

  updateIdentity: (id, data, options = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.UPDATE_IDENTITY_STARTED,
      });
      const response = await updateIdentityById(id, data);
      if (response.status === 200) {
        if (options.showToast !== false) {
          showToast(null, {
            type: 'success',
            text: options?.message?.onSuccess || 'Your changes were saved successfully!',
          });
        }

        dispatch({
          type: actions.UPDATE_IDENTITY_SUCCESS,
          payload: response?.data,
        });

        if (options?.redirectTo) {
          const splitURL = options.redirectTo?.split('?');
          if (splitURL.length > 1) {
            getHistory().push({ pathname: splitURL[0], search: splitURL[1] });
          } else {
            getHistory().push({ pathname: options.redirectTo });
          }
        } else {
          getHistory().push(`${IDENTITIES_INFORMATION}/${response?.data?.id}`);
        }
      }
    } catch (error) {
      dispatch({
        type: actions.UPDATE_IDENTITY_ERROR,
      });
    }
  },

  getIdentityById: (id) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_IDENTITY_BY_ID_STARTED,
      });
      const data = await getIdentityById(id);
      dispatch({
        type: actions.GET_IDENTITY_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_IDENTITY_BY_ID_ERROR,
      });
      console.error(error);
    }
  },

  deleteIdentity: (id, options = {}) => async (dispatch) => {
    try {

      dispatch({
        type: actions.DELETE_IDENTITY_BY_ID_STARTED,
      });

      const response = await deleteIdentityById(id, options);
      if (response.status === 204) {
        if (options.showToast !== false) {
          showToast(null, {
            type: 'success',
            text: options?.message?.onSuccess || 'Identity Deleted!',
          });
        }

        dispatch({
          type: actions.DELETE_IDENTITY_BY_ID_SUCCESS,
          payload: { id },
        });

        if (options?.redirectTo) {
          getHistory().push({ pathname: options.redirectTo });
        }
        //  else {
        //   getHistory().push(IDENTITIES_LIST);
        // }
      }
    } catch (error) {
      dispatch({
        type: actions.DELETE_IDENTITY_BY_ID_ERROR,
      });
    }
  },
};

export default actions;

