import { toast } from 'react-toastify';
import { hydraAdminClient, hydraPublicClient } from './config';
import routes from './routes';

export const getClients = async (id = undefined, pagination = {}) => {
  try {
    const queryParams = new URLSearchParams(pagination).toString();
    const option = {
      method: 'GET',
      url: `${routes.GET_CLIENTS}?${queryParams}`,
    };
    let openIdConfig = {};
    if (id) {
      option.url = `${routes.GET_CLIENTS}/${id}`;
      openIdConfig = await getOpenIdConnectConfig();
    }

    let response = await hydraAdminClient(option);
    if (id) {
      return {
        data: {
          ...response?.data,
          openIdConfig,
        },
        count: parseInt(response.headers['x-total-count']),
      };
    }
    return {
      data: response?.data,
      count: parseInt(response.headers['x-total-count']),
    };
  } catch (error) {
    if (id) {
      toast.error('Ane error occurred while fetching the client');
    } else {
      toast.error('Ane error occurred while fetching client sites');
    }
    throw error;
  }
};

export const createClient = async (payload) => {
  try {
    const { data } = await hydraAdminClient({
      method: 'POST',
      url: routes.CREATE_CLIENT,
      data: payload,
    });
    toast.success('New client site registered');
    return data;
  } catch (error) {
    const errCtx =
      error?.response?.data?.error_description ||
      'Failed to register client site';
    toast.error(errCtx);
    throw error;
  }
};

export const deleteClient = async (clientId) => {
  try {
    const { data } = await hydraAdminClient({
      method: 'DELETE',
      url: `${routes.CREATE_CLIENT}/${clientId}`,
    });
    toast.success('Client deleted');
    return data;
  } catch (error) {
    const errCtx =
      error?.response?.data?.error_description || 'Failed to delete a client';
    toast.error(errCtx);
    throw error;
  }
};

export const getOpenIdConnectConfig = async () => {
  try {
    const { data } = await hydraPublicClient({
      method: 'GET',
      url: routes.GET_OPEN_ID_CONFIG,
    });
    return data;
  } catch (error) {
    toast.error('Failed to fetch openid connect config');
    throw error;
  }
};

export const editClient = async (clientId, payload) => {
  try {
    const { data } = await hydraAdminClient({
      method: 'PUT',
      url: `${routes.CREATE_CLIENT}/${clientId}`,
      data: payload,
    });
    toast.success('Client updated');
    return data;
  } catch (error) {
    const errCtx =
      error?.response?.data?.error_description || 'Failed to update the client';
    toast.error(errCtx);
    throw error;
  }
};

