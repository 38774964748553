import axios from 'axios';
import environment from '../../constants/env';
import oryEnv from '../ory/env';
import env from './env';

const commonOptions = {};

if (environment.ENV === 'production') {
  commonOptions.headers = {
    Authorization: 'Bearer ' + oryEnv.ORY_ACCESS_TOKEN,
  };
}

export const ketoWriteClient = axios.create({
  baseURL: env.KETO_WRITE_API_URL,
  ...commonOptions,
});

export const ketoReadClient = axios.create({
  baseURL: env.KETO_READ_API_URL,
  ...commonOptions,
});

export const siteAccessConfig = {
  relation: 'access',
  namespace: 'sites',
};

export const communityConfig = {
  relation: 'read',
  namespace: 'sverige_community'
}

export const cultureConfig = {
  relation: 'read',
  namespace: 'sverige_culture'
}

export const businessConfig = {
  relation: 'read',
  namespace: 'sverige_business'
}
