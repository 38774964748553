export const config = {
  LOGO: require('assets/img/logo.png').default,
  SITE_LOGO: require('assets/img/logo.png').default,
  PLAYSTORE: require('assets/img/google-play.png').default,
  APPSTORE: require('assets/img/apple.png').default,
  MENUICON: require('assets/img/align-left.png').default,
  PROFILE: require('assets/img/profile-pic-default.png').default,
  PERSONAL_INFO: require('assets/img/search.png').default,
  VOULT: require('assets/img/voult.png').default,
  SECURITY: require('assets/img/shield.png').default,
};

