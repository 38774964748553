import { toast } from 'react-toastify';
import { oryAdminAPI } from './config';
import adminRoutes from './routes';

export const getIdentityById = async (id) => {
  try {
    const requestOptions = {
      method: 'GET',
      url: `${adminRoutes.GET_IDENTITY_BY_ID}/${id}`,
    };
    const { data } = await oryAdminAPI(requestOptions);
    return data;
  } catch (error) {
    toast.error('An error occurred while retrieving an identity');
    throw error;
  }
};

export const getIdentities = async (pagination = {}) => {
  try {
    const queryParams = new URLSearchParams(pagination).toString();
    const requestOptions = {
      method: 'GET',
      url: `${adminRoutes.LIST_IDENTITIES}?${queryParams}`,
    };
    const response = await oryAdminAPI(requestOptions);
    return {
      data: response?.data,
      count: parseInt(response.headers['x-total-count']),
    };
  } catch (error) {
    toast.error('An error occurred while retrieving identities');
    throw error;
  }
};

export const updateIdentityById = async (id, payload) => {
  try {
    const requestOptions = {
      method: 'PUT',
      data: payload,
      url: `${adminRoutes.UPDATE_IDENTITY_BY_ID}/${id}`,
    };
    const response = await oryAdminAPI(requestOptions);
    return response;
  } catch (error) {
    toast.error('An error occurred while saving changes');
    throw error;
  }
};

export const deleteIdentityById = async (id, options = {}) => {
  try {
    const requestOptions = {
      method: 'DELETE',
      url: `${adminRoutes.DELETE_IDENTITY_BY_ID}/${id}`,
    };
    const response = await oryAdminAPI(requestOptions);
    return response;
  } catch (error) {
    toast.error(
      options?.message?.onError || 'An error occurred while deleting identity'
    );
    throw error;
  }
};

