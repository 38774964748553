import { CLIENT_LIST } from 'constants/routes';
import { getHistory } from 'store';
import {
  createClient,
  deleteClient,
  editClient,
  getClients,
} from '../api/hydra/service';

const actions = {
  GET_CLIENT_SITES_STARTED: 'GET_CLIENT_SITES_STARTED',
  GET_CLIENT_SITES_SUCCESS: 'GET_CLIENT_SITES_SUCCESS',
  GET_CLIENT_SITES_ERROR: 'GET_CLIENT_SITES_ERROR',
  GET_CLIENT_SITE_BY_ID_STARTED: 'GET_CLIENT_SITE_BY_ID_STARTED',
  GET_CLIENT_SITE_BY_ID_SUCCESS: 'GET_CLIENT_SITE_BY_ID_SUCCESS',
  GET_CLIENT_SITE_BY_ID_ERROR: 'GET_CLIENT_SITE_BY_ID_ERROR',
  CREATE_CLIENT_STARTED: 'CREATE_CLIENT_STARTED',
  CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_ERROR: 'CREATE_CLIENT_ERROR',
  DELETE_CLIENT_STARTED: 'DELETE_CLIENT_STARTED',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERROR: 'DELETE_CLIENT_ERROR',
  EDIT_CLIENT_STARTED: 'EDIT_CLIENT_STARTED',
  EDIT_CLIENT_SUCCESS: 'EDIT_CLIENT_SUCCESS',
  EDIT_CLIENT_ERROR: 'EDIT_CLIENT_ERROR',
  getClients: (pagination = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_CLIENT_SITES_STARTED,
      });
      const response = await getClients(undefined, pagination);
      dispatch({
        type: actions.GET_CLIENT_SITES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_CLIENT_SITES_ERROR,
      });
    }
  },

  createClient: (payload, redirectTo = undefined) => async (dispatch) => {
    try {
      dispatch({
        type: actions.CREATE_CLIENT_STARTED,
      });
      const response = await createClient(payload);
      dispatch({
        type: actions.CREATE_CLIENT_SUCCESS,
        payload: response,
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    } catch (error) {
      dispatch({
        type: actions.CREATE_CLIENT_ERROR,
      });
    }
  },
  deleteClient: (clientId, redirectTo = undefined) => async (dispatch) => {
    try {
      dispatch({
        type: actions.DELETE_CLIENT_STARTED,
      });
      await deleteClient(clientId);
      dispatch({
        type: actions.DELETE_CLIENT_SUCCESS,
        payload: { clientId },
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    } catch (error) {
      dispatch({
        type: actions.DELETE_CLIENT_ERROR,
      });
    }
  },
  getClientById: (id, redirectOnFailure) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_CLIENT_SITE_BY_ID_STARTED,
      });
      const response = await getClients(id);
      dispatch({
        type: actions.GET_CLIENT_SITE_BY_ID_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_CLIENT_SITE_BY_ID_ERROR,
      });
      if (redirectOnFailure) {
        getHistory().push(redirectOnFailure);
      }
      getHistory().push(CLIENT_LIST);
    }
  },
  editClient: (clientId, payload, redirectTo = undefined) => async (
    dispatch
  ) => {
    try {
      dispatch({
        type: actions.EDIT_CLIENT_STARTED,
      });
      const response = await editClient(clientId, payload);
      dispatch({
        type: actions.EDIT_CLIENT_SUCCESS,
        payload: response,
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    } catch (error) {
      dispatch({
        type: actions.EDIT_CLIENT_ERROR,
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    }
  },
};

export default actions;

